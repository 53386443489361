import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import ListPropertyForm from "../components/property/list-property-form"
import Container from '@material-ui/core/Container';


const ListProperty = () => {
  return (
    <Layout>
      <SEO title="List Your Property" ogImage="https://images.ctfassets.net/jld0bgdfw9eu/1J0BwTKIgCHLeTvtzlsa17/0e98478a454e437f9cfc01030c6a6d27/ogimage.png" />
      <Container fixed style={{ minHeight: '100vh' }}>
        <h1>List your property with us.</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <ListPropertyForm />
      </Container>
    </Layout>
  )};

export default ListProperty
