import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ListPropertyForm = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>

      <form className={classes.container} noValidate autoComplete="off" name="list-property" method="POST" action="/contact-success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="list-property" />
        <div>
          <TextField
            id="name"
            name="Name"
            label="Name"
            helperText="Your name"
            required
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="email"
            label="Email"
            name="Email"
            helperText="Your email address"
            required
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="phone"
            name="Phone"
            label="Phone"
            helperText="We can we best reach you at?"
            required
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="address"
            name="Address"
            label="Address"
            helperText="Where about is your property located at?"
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="vrbo"
            name="VRBO_link"
            label="VRBO link"
            helperText="A link to your VRBO if you have one."
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="AirBNB"
            name="AirBNB_link"
            label="AirBNB link"
            helperText="A link to your AirBNB if you have one."
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="other1"
            name="other1"
            label="Other #1"
            helperText="Any aditional links you may have"
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="other2"
            name="other2"
            label="Other #2"
            helperText="Any aditional links you may have"
            className={classes.textField}
            margin="normal"
          />
        </div>
        <div>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button} 
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>

    </div>
  )
};

export default ListPropertyForm
